import img1 from "../Assets/Img/poster1 (1).png"
import img2 from "../Assets/Img/poster2.png"
import img3 from "../Assets/Img/poster3.png"
import img4 from "../Assets/Img/poster4.png"

export const sliderTableHead = [
    {
        title: "Slider Tittle"
    },
    {
        title: "Slider Image"
    },
    {
        title: "Status"
    },
    {
        title: "Action"
    },
]

export const sliderTableBody = [
    {
        name: "Selfiee",
        poster: img1,
        status: "Active"

    },
    {
        name: "Pupsha : The Rise",
        poster: img2,
        status: "Active"

    },
    {
        name: "Patashala",
        poster: img3,
        status: "Active"

    },
    {
        name: "Jailer",
        poster: img4,
        status: "Active"

    },
]