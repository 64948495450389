
export const adminTableHead = [
    {
        title: "Name"
    },
    {
        title: "Email"
    },
    {
        title: "Phone"
    },
    {
        title: "Admin Type"
    },
    {
        title: "Status"
    },
    {
        title: "Action"
    },
]

export const adminTableBody = [
    {
        name: "Raja Mohan",
        email: "rajamohan@gmail.com",
        phone: +91823456781,
        adminType: "Sub Admin",

        status: "Active"
    },
    {
        name: "Raja Mohan",
        email: "rajamohan@gmail.com",
        phone: +91823456781,
        adminType: "Admin",

        status: "Active"
    },
    {
        name: "Raja Mohan",
        email: "rajamohan@gmail.com",
        phone: +91823456781,
        adminType: " Sub Admin",

        status: "Active"
    },
    {
        name: "Raja Mohan",
        email: "rajamohan@gmail.com",
        phone: +91823456781,
        adminType: "Admin",

        status: "Active"
    },
    {
        name: "Raja Mohan",
        email: "rajamohan@gmail.com",
        phone: +91823456781,
        adminType: "Admin",

        status: "Active"
    },


]