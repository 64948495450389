import { combineReducers } from "redux";
import { loginReducer } from "./auth/reducer";
import { getLanguageReducer } from "./admin/reducer";
import { addLanguagesReducer } from "./admin/reducer";
import { updateLanguagesReducer } from "./admin/reducer";
import { deleteLanguagesReducer } from "./admin/reducer";
import { getLanguageByIDReducer } from "./admin/reducer";
import {
  getGenreListReducer,
  addGenreReducer,
  updateGenreReducer,
  deleteGenreReducer,
  getArtistListReducer,
  getArtistByIDReducer,
  addArtistReducer,
  updateArtistReducer,
  deleteArtistReducer,
  TechnicianListReducer,
  getTechnicianByIDReducer,
  addTechnicianReducer,
  updateTechinicianReducer,
  deleteTechnicianReducer,
  movieListReducer,
  addMovieReducer,
  updateMovieReducer,
  deleteMovieReducer,
  showListReducer,
  addShowReducer,
  updateShowReducer,
  deleteShowReducer,
  sessionListReducer,
  addSessionReducer,
  updateSessionReducer,
  deleteSessionReducer,
  episodeListReducer,
  addEpisodeReducer,
  updateEpisodeReducer,
  deleteEpisodeReducer,

} from "./admin/reducer";

export const rootReducer = combineReducers({
  loginReducer,
  getLanguageReducer,
  addLanguagesReducer,
  updateLanguagesReducer,
  getLanguageByIDReducer,
  deleteLanguagesReducer,
  getGenreListReducer,
  addGenreReducer,
  updateGenreReducer,
  deleteGenreReducer,
  getArtistListReducer,
  getArtistByIDReducer,
  addArtistReducer,
  updateArtistReducer,
  deleteArtistReducer,
  TechnicianListReducer,
  getTechnicianByIDReducer,
  addTechnicianReducer,
  updateTechinicianReducer,
  deleteTechnicianReducer,
  movieListReducer,
  addMovieReducer,
  updateMovieReducer,
  deleteMovieReducer,
  showListReducer,
  addShowReducer,
  updateShowReducer,
  deleteShowReducer,
  sessionListReducer,
  addSessionReducer,
  updateSessionReducer,
  deleteSessionReducer,
  episodeListReducer,
  addEpisodeReducer,
  updateEpisodeReducer,
  deleteEpisodeReducer,
});
