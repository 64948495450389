export const authActionTypes = {
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    LOGIN_FAILURE: "LOGIN_FAILURE",
    FORGET_REQUEST: "FORGET_REQUEST",
    FORGET_SUCCESS: "FORGET_SUCCESS",
    FORGET_FAILURE: "FORGET_FAILURE",
    CONFIRMPASSWORD_REQUEST: 'CONFIRMPASSWORD_REQUEST',
    CONFIRMPASSWORD_SUCCESS: 'CONFIRMPASSWORD_SUCCESS',
    CONFIRMPASSWORD_FAILURE: 'CONFIRMPASSWORD_FAILURE',
    LOGOUT_REQ : "LOGOUT_REQ",
}