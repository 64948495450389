const adminActionTypes = {

// Language ActionType

 LANGUAGE_REQUEST : "LANGUAGE_REQUEST",
 LANGUAGE_SUCCESS : "LANGUAGE_SUCCESS",
 LANGUAGE_FAILURE : "LANGUAGE_FAILURE",


 ADD_LANGUAGE_REQUEST : "ADD_LANGUAGE_REQUEST",
 ADD_LANGUAGE_SUCCESS : "ADD_LANGUAGE_SUCCESS",
 ADD_LANGUAGE_FAILURE : "ADD_LANGUAGE_FAILURE",

 LANGUAGE_BY_ID_REQUEST : "LANGUAGE_BY_ID_REQUEST",
 LANGUAGE_BY_ID_SUCCESS : "LANGUAGE_BY_ID_SUCCESS",
 LANGUAGE_BY_ID_FAILURE : "LANGUAGE_BY_ID_FAILURE",


 UPDATE_LANGUAGE_REQUEST : "UPDATE_LANGUAGE_REQUEST",
 UPDATE_LANGUAGE_SUCCESS : "UPDATE_LANGUAGE_SUCCESS",
 UPDATE_LANGUAGE_FAILURE : "UPDATE_LANGUAGE_FAILURE",


 DELETE_LANGUAGE_REQUEST : "DELETE_LANGUAGE_REQUEST",
 DELETE_LANGUAGE_SUCCESS : "DELETE_LANGUAGE_SUCCESS",
 DELETE_LANGUAGE_FAILURE : "DELETE_LANGUAGE_FAILURE",


// Genre ActionType

GENRE_REQUEST : "GENRE_REQUEST",
GENRE_SUCCESS : "GENRE_SUCCESS",
GENRE_FAILURE : "GENRE_FAILURE",


ADD_GENRE_REQUEST : "ADD_GENRE_REQUEST",
ADD_GENRE_SUCCESS : "ADD_GENRE_SUCCESS",
ADD_GENRE_FAILURE : "ADD_GENRE_FAILURE",

GENRE_BY_ID_REQUEST : "GENRE_BY_ID_REQUEST",
GENRE_BY_ID_SUCCESS : "GENRE_BY_ID_SUCCESS",
GENRE_BY_ID_FAILURE : "GENRE_BY_ID_FAILURE",


UPDATE_GENRE_REQUEST : "UPDATE_GENRE_REQUEST",
UPDATE_GENRE_SUCCESS : "UPDATE_GENRE_SUCCESS",
UPDATE_GENRE_FAILURE : "UPDATE_GENRE_FAILURE",


DELETE_GENRE_REQUEST : "DELETE_GENRE_REQUEST",
DELETE_GENRE_SUCCESS : "DELETE_GENRE_SUCCESS",
DELETE_GENRE_FAILURE : "DELETE_GENRE_FAILURE",

// Artist

ARTIST_REQUEST : "ARTIST_REQUEST",
 ARTIST_SUCCESS : "ARTIST_SUCCESS",
 ARTIST_FAILURE : "ARTIST_FAILURE",


 ADD_ARTIST_REQUEST : "ADD_ARTIST_REQUEST",
 ADD_ARTIST_SUCCESS : "ADD_ARTIST_SUCCESS",
 ADD_ARTIST_FAILURE : "ADD_ARTIST_FAILURE",

 ARTIST_BY_ID_REQUEST : "ARTIST_BY_ID_REQUEST",
 ARTIST_BY_ID_SUCCESS : "ARTIST_BY_ID_SUCCESS",
 ARTIST_BY_ID_FAILURE : "ARTIST_BY_ID_FAILURE",


 UPDATE_ARTIST_REQUEST : "UPDATE_ARTIST_REQUEST",
 UPDATE_ARTIST_SUCCESS : "UPDATE_ARTIST_SUCCESS",
 UPDATE_ARTIST_FAILURE : "UPDATE_ARTIST_FAILURE",


 DELETE_ARTIST_REQUEST : "DELETE_ARTIST_REQUEST",
 DELETE_ARTIST_SUCCESS : "DELETE_ARTIST_SUCCESS",
 DELETE_ARTIST_FAILURE : "DELETE_ARTIST_FAILURE",


// TECHNICIAN

TECHNICIAN_REQUEST : "TECHNICIAN_REQUEST",
TECHNICIAN_SUCCESS : "TECHNICIAN_SUCCESS",
TECHNICIAN_FAILURE : "TECHNICIAN_FAILURE",


ADD_TECHNICIAN_REQUEST : "ADD_TECHNICIAN_REQUEST",
ADD_TECHNICIAN_SUCCESS : "ADD_TECHNICIAN_SUCCESS",
ADD_TECHNICIAN_FAILURE : "ADD_TECHNICIAN_FAILURE",

TECHNICIAN_BY_ID_REQUEST : "TECHNICIAN_BY_ID_REQUEST",
TECHNICIAN_BY_ID_SUCCESS : "TECHNICIAN_BY_ID_SUCCESS",
TECHNICIAN_BY_ID_FAILURE : "TECHNICIAN_BY_ID_FAILURE",


UPDATE_TECHNICIAN_REQUEST : "UPDATE_TECHNICIAN_REQUEST",
UPDATE_TECHNICIAN_SUCCESS : "UPDATE_TECHNICIAN_SUCCESS",
UPDATE_TECHNICIAN_FAILURE : "UPDATE_TECHNICIAN_FAILURE",


DELETE_TECHNICIAN_REQUEST : "DELETE_TECHNICIAN_REQUEST",
DELETE_TECHNICIAN_SUCCESS : "DELETE_TECHNICIAN_SUCCESS",
DELETE_TECHNICIAN_FAILURE : "DELETE_TECHNICIAN_FAILURE",

// MOVIE

MOVIE_REQUEST : "MOVIE_REQUEST",
MOVIE_SUCCESS : "MOVIE_SUCCESS",
MOVIE_FAILURE : "MOVIE_FAILURE",


ADD_MOVIE_REQUEST : "ADD_MOVIE_REQUEST",
ADD_MOVIE_SUCCESS : "ADD_MOVIE_SUCCESS",
ADD_MOVIE_FAILURE : "ADD_MOVIE_FAILURE",

MOVIE_BY_ID_REQUEST : "MOVIE_BY_ID_REQUEST",
MOVIE_BY_ID_SUCCESS : "MOVIE_BY_ID_SUCCESS",
MOVIE_BY_ID_FAILURE : "MOVIE_BY_ID_FAILURE",


UPDATE_MOVIE_REQUEST : "UPDATE_MOVIE_REQUEST",
UPDATE_MOVIE_SUCCESS : "UPDATE_MOVIE_SUCCESS",
UPDATE_MOVIE_FAILURE : "UPDATE_MOVIE_FAILURE",


DELETE_MOVIE_REQUEST : "DELETE_MOVIE_REQUEST",
DELETE_MOVIE_SUCCESS : "DELETE_MOVIE_SUCCESS",
DELETE_MOVIE_FAILURE : "DELETE_MOVIE_FAILURE",


// SHOWS

SHOWS_REQUEST : "SHOWS_REQUEST",
SHOWS_SUCCESS : "SHOWS_SUCCESS",
SHOWS_FAILURE : "SHOWS_FAILURE",


ADD_SHOWS_REQUEST : "ADD_SHOWS_REQUEST",
ADD_SHOWS_SUCCESS : "ADD_SHOWS_SUCCESS",
ADD_SHOWS_FAILURE : "ADD_SHOWS_FAILURE",

SHOWS_BY_ID_REQUEST : "SHOWS_BY_ID_REQUEST",
SHOWS_BY_ID_SUCCESS : "SHOWS_BY_ID_SUCCESS",
SHOWS_BY_ID_FAILURE : "SHOWS_BY_ID_FAILURE",


UPDATE_SHOWS_REQUEST : "UPDATE_SHOWS_REQUEST",
UPDATE_SHOWS_SUCCESS : "UPDATE_SHOWS_SUCCESS",
UPDATE_SHOWS_FAILURE : "UPDATE_SHOWS_FAILURE",


DELETE_SHOWS_REQUEST : "DELETE_SHOWS_REQUEST",
DELETE_SHOWS_SUCCESS : "DELETE_SHOWS_SUCCESS",
DELETE_SHOWS_FAILURE : "DELETE_SHOWS_FAILURE",


// SESSION

SESSION_REQUEST : "SESSION_REQUEST",
SESSION_SUCCESS : "SESSION_SUCCESS",
SESSION_FAILURE : "SESSION_FAILURE",


ADD_SESSION_REQUEST : "ADD_SESSION_REQUEST",
ADD_SESSION_SUCCESS : "ADD_SESSION_SUCCESS",
ADD_SESSION_FAILURE : "ADD_SESSION_FAILURE",

SESSION_BY_ID_REQUEST : "SESSION_BY_ID_REQUEST",
SESSION_BY_ID_SUCCESS : "SESSION_BY_ID_SUCCESS",
SESSION_BY_ID_FAILURE : "SESSION_BY_ID_FAILURE",


UPDATE_SESSION_REQUEST : "UPDATE_SESSION_REQUEST",
UPDATE_SESSION_SUCCESS : "UPDATE_SESSION_SUCCESS",
UPDATE_SESSION_FAILURE : "UPDATE_SESSION_FAILURE",


DELETE_SESSION_REQUEST : "DELETE_SESSION_REQUEST",
DELETE_SESSION_SUCCESS : "DELETE_SESSION_SUCCESS",
DELETE_SESSION_FAILURE : "DELETE_SESSION_FAILURE",

// EPISODES

EPISODES_REQUEST : "EPISODES_REQUEST",
EPISODES_SUCCESS : "EPISODES_SUCCESS",
EPISODES_FAILURE : "EPISODES_FAILURE",


ADD_EPISODES_REQUEST : "ADD_EPISODES_REQUEST",
ADD_EPISODES_SUCCESS : "ADD_EPISODES_SUCCESS",
ADD_EPISODES_FAILURE : "ADD_EPISODES_FAILURE",

EPISODES_BY_ID_REQUEST : "EPISODES_BY_ID_REQUEST",
EPISODES_BY_ID_SUCCESS : "EPISODES_BY_ID_SUCCESS",
EPISODES_BY_ID_FAILURE : "EPISODES_BY_ID_FAILURE",


UPDATE_EPISODES_REQUEST : "UPDATE_EPISODES_REQUEST",
UPDATE_EPISODES_SUCCESS : "UPDATE_EPISODES_SUCCESS",
UPDATE_EPISODES_FAILURE : "UPDATE_EPISODES_FAILURE",


DELETE_EPISODES_REQUEST : "DELETE_EPISODES_REQUEST",
DELETE_EPISODES_SUCCESS : "DELETE_EPISODES_SUCCESS",
DELETE_EPISODES_FAILURE : "DELETE_EPISODES_FAILURE",

// SLIDER

SLIDER_REQUEST : "SLIDER_REQUEST",
SLIDER_SUCCESS : "SLIDER_SUCCESS",
SLIDER_FAILURE : "SLIDER_FAILURE",


ADD_SLIDER_REQUEST : "ADD_SLIDER_REQUEST",
ADD_SLIDER_SUCCESS : "ADD_SLIDER_SUCCESS",
ADD_SLIDER_FAILURE : "ADD_SLIDER_FAILURE",

SLIDER_BY_ID_REQUEST : "SLIDER_BY_ID_REQUEST",
SLIDER_BY_ID_SUCCESS : "SLIDER_BY_ID_SUCCESS",
SLIDER_BY_ID_FAILURE : "SLIDER_BY_ID_FAILURE",


UPDATE_SLIDER_REQUEST : "UPDATE_SLIDER_REQUEST",
UPDATE_SLIDER_SUCCESS : "UPDATE_SLIDER_SUCCESS",
UPDATE_SLIDER_FAILURE : "UPDATE_SLIDER_FAILURE",


DELETE_SLIDER_REQUEST : "DELETE_SLIDER_REQUEST",
DELETE_SLIDER_SUCCESS : "DELETE_SLIDER_SUCCESS",
DELETE_SLIDER_FAILURE : "DELETE_SLIDER_FAILURE",


// HOME_SECTION

 HOME_SECTION_REQUEST : "HOME_SECTION_REQUEST",
 HOME_SECTION_SUCCESS : "HOME_SECTION_SUCCESS",
 HOME_SECTION_FAILURE : "HOME_SECTION_FAILURE",


 ADD_HOME_SECTION_REQUEST : "ADD_HOME_SECTION_REQUEST",
 ADD_HOME_SECTION_SUCCESS : "ADD_HOME_SECTION_SUCCESS",
 ADD_HOME_SECTION_FAILURE : "ADD_HOME_SECTION_FAILURE",

 HOME_SECTION_BY_ID_REQUEST : "HOME_SECTION_BY_ID_REQUEST",
 HOME_SECTION_BY_ID_SUCCESS : "HOME_SECTION_BY_ID_SUCCESS",
 HOME_SECTION_BY_ID_FAILURE : "HOME_SECTION_BY_ID_FAILURE",


 UPDATE_HOME_SECTION_REQUEST : "UPDATE_HOME_SECTION_REQUEST",
 UPDATE_HOME_SECTION_SUCCESS : "UPDATE_HOME_SECTION_SUCCESS",
 UPDATE_HOME_SECTION_FAILURE : "UPDATE_HOME_SECTION_FAILURE",


 DELETE_HOME_SECTION_REQUEST : "DELETE_HOME_SECTION_REQUEST",
 DELETE_HOME_SECTION_SUCCESS : "DELETE_HOME_SECTION_SUCCESS",
 DELETE_HOME_SECTION_FAILURE : "DELETE_HOME_SECTION_FAILURE",


}

export default adminActionTypes;