import { Box, Button, Checkbox, Flex, Input, Select, Stack, Text } from "@chakra-ui/react";
import React from "react";
import BackButton from "../../../Components/Custom/BackButton";


const AddSlider = () => {

  return (
    <Box bg="#1C1C1E" color="white" w="100%" borderRadius="5px" p="20px">
      <BackButton page={"/home/slider"}/>
      <Flex mt="30px">
        <Text w="30%" color="white" textTransform="inherit" fontSize="15px">
          Slider Name
        </Text>
        <Input
          type="text"
          w="60%"
          h="40px"
          border="0px"
          bg="#232629"
          placeholder=""
        />
      </Flex>

      <Flex mt="30px">
        <Text w="30%" fontSize="15px">
          Slider Image
        </Text>
        <Flex w="60%" flexDirection="column">
          <Select w="100%" h="40px" bg="#232629" border="none" color="white">
            <option value="">select</option>
            <option value="">document/Technician/technicianName</option>
            <option value="">No</option>
          </Select>
          <Text fontSize="10px">(Recommended Resolution : 180X140)</Text>
        </Flex>
      </Flex>

      <Flex mt="30px">
        <Text w="30%" fontSize="15px">
          Post Type
        </Text>
        <Select w="60%" h="40px" bg="#232629" border="none" color="white">
          <option value="">select</option>
          <option value="">Movies</option>
          <option value="">Drama</option>
        </Select>
      </Flex>

      <Flex mt="30px">
          <Text w="30%" fontSize="15px">Movies</Text>
        <Stack spacing={[1, 5]} direction={["column", "row"]}>
          <Checkbox size="lg" colorScheme="red">
            Home
          </Checkbox>
          <Checkbox size="lg" colorScheme="green" >
            Tv Showz
          </Checkbox>
          <Checkbox size="lg" colorScheme="orange">
            Movies
          </Checkbox>
          <Checkbox size="lg" colorScheme="orange">
            New & Popular
          </Checkbox>
        </Stack>
      </Flex>

      <Flex mt="30px">
        <Text w="30%" fontSize="15px">
          status
        </Text>
        <Select w="60%" h="40px" bg="#232629" border="none" color="white">
          <option value="">select</option>
          <option value="">Yes</option>
          <option value="">No</option>
        </Select>
      </Flex>
      <Button
              bg="#E50813"
              color="white"
              _hover={{ color: "#E50813", bg: "white" }}
              ml="30%"
              mt="40px"

            >
              Save
            </Button>
    </Box>
  );
};

export default AddSlider;
